import axios from "axios";
import Vue from 'vue';

const searchMutations = {
    mutateDisplayNavbar(state, value) {
        state.isDisplayNavbar = value;
    },
    mutateHideNavbar(state) {
        state.isDisplayNavbar = false;
    },
    mutateIsReSearch(state, value) {
        state.isReSearch = value;
    },
    mutateIsStock(state, value) {
        state.isStock = value;
    },
    mutateIsAllGenre(state, value) {
        state.isAllGenreChecked = value;
    },
    incrementPage(state) {
        state.page++;
    },
    incrementVectorSearchPage(state) {
        state.vectorSearch.page++;
    },
    mutatePage(state, value) {
        state.page = value;
    },
    mutateVectorSearchPage(state, value) {
        state.vectorSearch.page = value;
    },
    mutateSearchWord(state, value) {
        state.search_word = value;
    },
    mutateKanaReadings(state, value) {
        state.kana_readings = value;
    },
    mutateOtherReadings(state, value) {
        state.other_readings = value;
    },
    applySearchResults(state, value) {
        state.search_results.push(...value);
    },
    applySearchResultsGenre(state, value) {
        state.search_results_genre.push(value);
    },
    applySearchResultsTitle(state, value) {
        state.search_results_title.push(...value);
    },
    applyVectorSearchResults(state, value) {
        state.vectorSearch.results.push(...value);
    },
    addToSearchResults(state, value) {
        state.search_results.unshift(...value);
    },
    removeFromSearchResults(state, value) {
        // library_item_idが一致する要素を削除
        state.search_results = state.search_results.filter(function (item) {
            return item.library_item_id !== value;
        });
    },
    mutateIsDateNotFound(state, value) {
        state.isDateNotFound = value;
    },
    mutateIsVectorDataNotFound(state, value) {
        state.vectorSearch.isDataNotFound = value;
    },
    mutateIsSearchError(state, value) {
        state.isSearchError = value;
    },
    mutateIsVectorSearchError(state, value) {
        state.vectorSearch.isSearchError = value;
    },
    mutateIsComplete(state, value) {
        state.isComplete = value;
    },
    mutateIsVectorSearchComplete(state, value) {
        state.vectorSearch.isComplete = value;
    },
    mutateIsLoading(state, value) {
        state.isLoading = value;
    },
    mutateIsVectorSearchLoading(state, value) {
        state.vectorSearch.isLoading = value;
    },
    mutateSelectedCategories(state, value) {
        state.selectedCategories = value;
    },
    mutateSelectedSellPrices(state, value) {
        state.selectedSellPrices = value;
    },
    mutateSelectedDiscountRate(state, value) {
        state.selectedDiscountRate = value;
    },
    mutateSelectedPublishDate(state, value) {
        state.selectedPublishDate = value;
    },
    mutateSelectedBookAge(state, value) {
        state.selectedBookAge = value;
    },
    mutateSelectedTopPopularity(state, value) {
        state.selectedTopPopularity = value;
    },
    mutateSelectedSortCode(state, value) {
        state.selectedSortCode = value;
    },
    resetSearchResults(state) {
        state.search_results = [];
    },
    resetSearchCategory(state) {
        state.search_results_genre = [];
    },
    resetPage(state) {
        state.page = 0;
    },
    // vuetifyのresetでは任意の値に初期化が出来ないためstateをコピーして初期化
    resetState(state) {
        let defaultState = defaultSearchState();
        delete defaultState.search_word;
        delete defaultState.search_results;
        delete defaultState.search_results_genre;
        delete defaultState.page;
        delete defaultState.isDisplayNavbar;
        delete defaultState.kana_readings;
        delete defaultState.other_readings;
        Object.assign(state, defaultState)
    },
    // べクトル検索のstateを初期化
    resetVectorSearchState(state) {
        state.vectorSearch = {
            results: [],
            count: 0,
            page: 0,
            isComplete: false,
            isLoading: false,
            isDataNotFound: false,
            isSearchError: false,
        };
    },
    resetStateByIgnoreList(state, ignoreList) {
        let defaultState = defaultSearchState();
        ignoreList.forEach((key) => {
            delete defaultState[key];
        });
        Object.assign(state, defaultState)
    },
    mutateIsPriceDisplay(state, value) {
        state.isPriceDisplay = value;
    },
    mutateIsPublishDateDisplay(state, value) {
        state.isPublishDateDisplay = value;
    },
    mutateIsDisplayOverlayLoading(state, value) {
        state.isDisplayOverlayLoading = value;
    },
    mutateCount(state, value) {
        state.count = value;
    },
    mutateVectorSearchCount(state, value) {
        state.vectorSearch.count = value;
    },
    mutateIsGetGenre(state, value) {
        state.isGetGenre = value;
    },
    resetSelectedGenre(state) {
        state.selectedCategories = [];
    },
    mutateIsItemSelectable(state, value) {
        state.isItemSelectable = value;
    },
    setSelectedItemsIds(state, payload) {
        state.selectedItemsIds = payload;
    },
    setApiEndPoint(state, payload) {
        state.apiEndPoint = payload;
    },
    setGenreApiEndPoint(state, payload) {
        state.genreApiEndPoint = payload;
    },
    mutateTags(state, payload) {
        state.tags = payload;
    },
    resetSearchResultsTitle(state) {
        state.search_results_title = [];
    },
    mutateTagSearchDisplaySize(state, value) {
        state.tagSearchDisplaySize = value;
    },
    mutateTitleSearchDisplaySize(state, value) {
        state.titleSearchDisplaySize = value;
    },
    mutateNumberOfDuplicates(state, value) {
        state.numberOfDuplicates = value;
    },
    mutateShowSortingDialog(state, value) {
        state.showSortingDialog = value;
    },
    setIsAllSelected(state, value) {
        state.isAllSelected = value;
    },
    mutateAlterNativeSearchWords(state,value){
        state.alterNativeSearchWords = value;
    }
}
const searchActions = {
    async research({state, dispatch, commit}) {
        commit('mutateIsReSearch', true);
        commit('resetPage');
        commit('resetSearchResults');
        commit('resetVectorSearchState');
        commit('mutateIsDateNotFound', false);
        commit('mutateIsSearchError', false);
        commit('mutateIsComplete', false);
        commit('mutateHideNavbar');
        commit('mutateIsDisplayOverlayLoading', true);
        await dispatch('searchItem');
        if (state.isGetGenre) {
            commit('resetSearchCategory');
            dispatch('searchGenre');
        }
    },
    searchGenre({state, dispatch, commit, getters}, payload) {
        const api = state.genreApiEndPoint;

        return axios
            .get(api, {
                params: {
                    page: null,
                    search_word: state.search_word,
                    kana_readings: state.kana_readings,
                    other_readings: state.other_readings,
                    conditions_stock: state.isStock,
                    conditions_category: null,
                    conditions_sorting: state.selectedSortCode,
                    ...(
                        getters.getIsPriceDisplay ?
                            getters.getSelectedSellPricePayload ? {conditions_price: getters.getSelectedSellPricePayload} : '' :
                            getters.getSelectedDiscountRatePayload ? {conditions_rate: getters.getSelectedDiscountRatePayload} : ''),
                    ...(
                        getters.getIsPublishDateDisplay ?
                            getters.getSelectedPublishDatePayload ? {conditions_age: getters.getSelectedPublishDatePayload} : ''
                            : getters.getSelectedBookAgePayload ? {conditions_age: getters.getSelectedBookAgePayload} : ''
                    ),
                    ...(state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE ? {conditions_popularGoods: state.selectedTopPopularity} : '')
                },
            })
            .then(({data}) => {
                if (data) {
                    commit('applySearchResultsGenre', data.genre);
                }
            })
            .catch((error) => {
                //FIXME
                // commit('mutateIsSearchError', true)
            })
            .finally(() => {
            });
    },
    searchItem({state, dispatch, commit, getters}) {
        if (state.isComplete) {
            return;
        }
        const api = state.apiEndPoint;

        // FIXME: ライブラリの初回検索が2ページ分取得してないため暫定的にページ数を制御
        if (api == "/api/search") {
            state.page === 1 && commit('mutatePage', 2);
        }
        commit('incrementPage');
        commit('mutateIsLoading', true);
        let optionalDisplaySize = null;
        // 表示件数の指定がある場合はパラメータ値に含める
        if (state.tags && state.tagSearchDisplaySize) {
            optionalDisplaySize = state.tagSearchDisplaySize;
        }
        const maxItemNumber = optionalDisplaySize ? optionalDisplaySize : VueConfig.search.SEARCH_ITEMS_PER_PAGE;

        return axios.get(api, {
            params: {
                page: state.page,
                search_word: state.search_word,
                kana_readings: state.kana_readings,
                other_readings: state.other_readings,
                conditions_stock: state.isStock ? '2' : '1',
                conditions_sorting: state.selectedSortCode,
                ...(state.selectedCategories.length ? {conditions_category: state.selectedCategories} : ''),
                ...(
                    getters.getIsPriceDisplay ?
                        getters.getSelectedSellPricePayload ? {conditions_price: getters.getSelectedSellPricePayload} : '' :
                        getters.getSelectedDiscountRatePayload ? {conditions_rate: getters.getSelectedDiscountRatePayload} : ''),
                ...(
                    getters.getIsPublishDateDisplay ?
                        getters.getSelectedPublishDatePayload ? {conditions_age: getters.getSelectedPublishDatePayload} : '' :
                        getters.getSelectedBookAgePayload ? {conditions_age: getters.getSelectedBookAgePayload} : ''
                ),
                ...(state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE ? {conditions_popularGoods: state.selectedTopPopularity} : ''),
                tags: state.tags,
                ...(optionalDisplaySize ? {display_size: optionalDisplaySize} : ''),
                search_keyword_list:state.alterNativeSearchWords
            },
        })
            .then(({data}) => {
                if (!data) {
                    return;
                }
                // 検索結果にお気に入りフラグを付与
                updateFavoriteFlag(data.items);

                if (api.match('/api/library/folder/.+/items'.replace('/', '\/'))) {
                    commit('applySearchResults', data.items.filter(v => v));
                } else {
                    commit('applySearchResults', data.items);
                }
                commit('mutateIsDisplayOverlayLoading', false);
                if (Number.isFinite(data.count)) {
                    commit('mutateCount', data.count);
                }

                //追加の検索結果がない場合
                if (data.items.length === 0 || data.items.length < maxItemNumber) {
                    commit('mutateIsComplete', true);
                }

                //検索結果が全くない
                if (getters.getSearchResultsCount === 0) {
                    commit('mutateIsDateNotFound', true);
                }
                let searchKeywords = data.search_keywords;
                if (getters.getPageNumber === 1 &&searchKeywords && searchKeywords.length > 0){
                    commit("mutateAlterNativeSearchWords",searchKeywords)
                }
            })
            .catch((error) => {
                commit('mutateIsSearchError', true);
                commit('mutateIsLoading', false);
            })
            .finally(() => {

                let isConditionsPricesChanged = false;
                let isConditionsDateChanged = false;
                let isConditionsStockChanged = false;

                // 再検索実行時にURLのGETパラメータを更新
                if (state.isReSearch) {

                    const currentUrl = new URL(location.href);

                    let requestUrl = new URL(location.protocol + location.hostname);
                    state.search_word && requestUrl.searchParams.append('keyword', state.search_word);
                    if (state.kana_readings && state.kana_readings.length && state.kana_readings !== '[]') {
                        requestUrl.searchParams.append('kana_words', state.kana_readings);
                    }
                    if (state.other_readings) {
                        requestUrl.searchParams.append('other_readings', state.other_readings);
                    }
                    state.selectedSortCode && requestUrl.searchParams.append('conditions_sorting', state.selectedSortCode);

                    const isStockId = state.isStock ? '2' : '1';
                    if (currentUrl.searchParams.get('conditions_stock') !== isStockId) {
                        isConditionsStockChanged = true;
                    }
                    requestUrl.searchParams.append('conditions_stock', isStockId);

                    if (getters.getSelectedCategories.length) {
                        state.selectedCategories.forEach(item => {
                            requestUrl.searchParams.append('conditions_category[]', item);
                        });
                    }

                    if (getters.getIsPriceDisplay) {
                        if (getters.getSelectedSellPricePayload) {
                            isConditionsPricesChanged = true;
                            state.selectedSellPrices.forEach(item => {
                                requestUrl.searchParams.append('conditions_price[]', (item !== VueConfig.search.MIN_NO_SPECIFIED_VALUE && item !== VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? item : '');
                            });
                        }
                    } else {
                        if (getters.getSelectedDiscountRatePayload) {
                            isConditionsPricesChanged = true;
                            state.selectedDiscountRate.forEach(item => {
                                requestUrl.searchParams.append('conditions_rate[]', (item !== VueConfig.search.MIN_NO_SPECIFIED_VALUE && item !== VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? item : '');
                            });
                        }
                    }
                    if (getters.getIsPublishDateDisplay) {
                        if (getters.getSelectedPublishDatePayload) {
                            isConditionsDateChanged = true;
                            state.selectedPublishDate.forEach(item => {
                                requestUrl.searchParams.append('conditions_age[]', (item !== VueConfig.search.MIN_NO_SPECIFIED_VALUE && item !== VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? item : '');
                            });
                        }
                    } else {
                        if (getters.getSelectedBookAgePayload) {
                            isConditionsDateChanged = true;
                            state.selectedBookAge.forEach(item => {
                                requestUrl.searchParams.append('conditions_ageOfBooks[]', (item !== VueConfig.search.MIN_NO_SPECIFIED_VALUE && item !== VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? item : '');
                            });
                        }
                    }
                    state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE && requestUrl.searchParams.append('conditions_popularGoods', state.selectedTopPopularity);

                    // history.replaceState(
                    history.pushState(
                        "",
                        "",
                        location.pathname + requestUrl.search
                    );
                }

                // 価格または日付で検索条件の指定（初期値ではない）がある場合ジャンルを再取得
                if (isConditionsPricesChanged || isConditionsDateChanged || isConditionsStockChanged) {
                    commit('mutateIsGetGenre', true);
                }
                commit('mutateIsLoading', false);
            });
    },
    searchTitleItem({state, dispatch, commit, getters}, payload) {

        const api = "/api/searchTitle";
        const pageNumber = 1;
        const isStock = '1';
        const sortCode = 1;

        let optionalDisplaySize = null;
        if (state.titleSearchDisplaySize) {
            optionalDisplaySize = state.titleSearchDisplaySize;
        }

        return axios
            .get(api, {
                params: {
                    page: pageNumber,
                    search_word: payload['keyword'],
                    kana_readings: payload['kanaReadings'],
                    other_readings: payload['otherReadings'],
                    conditions_stock: isStock,
                    conditions_sorting: sortCode,
                    ...(optionalDisplaySize ? {display_size: optionalDisplaySize} : ''),
                },
            })
            .then(({data}) => {
                if (data && data.items[0]) {
                    commit('applySearchResultsTitle', [data.items[0]]);

                    pushToStoreIfFavorite([data.items[0]], dispatch)
                }
                state.titleSearchCount++;
            })
            .catch((error) => {
                // FIXME error
            });
    },
    vectorSearchItems({state, commit, getters}, payload) {
        if (state.vectorSearch.isComplete) {
            return;
        }
        const api = "/api/vectorSearch";
        const maxItemNumber = VueConfig.search.SEARCH_ITEMS_PER_PAGE;

        state.vectorSearch.page === 1 && commit('mutateVectorSearchPage', 2);
        commit('incrementVectorSearchPage');
        commit('mutateIsVectorSearchLoading', true);

        return axios
          .get(api, {
              params: {
                  page: state.vectorSearch.page,
                  search_word: state.search_word,
                  kana_readings: state.kana_readings,
                  other_readings: state.other_readings,
                  conditions_stock: state.isStock ? '2' : '1',
                  conditions_sorting: state.selectedSortCode,
                  ...(state.selectedCategories.length ? {conditions_category: state.selectedCategories} : ''),
                  ...(
                    getters.getIsPriceDisplay ?
                      getters.getSelectedSellPricePayload ? {conditions_price: getters.getSelectedSellPricePayload} : '' :
                      getters.getSelectedDiscountRatePayload ? {conditions_rate: getters.getSelectedDiscountRatePayload} : ''),
                  ...(
                    getters.getIsPublishDateDisplay ?
                      getters.getSelectedPublishDatePayload ? {conditions_age: getters.getSelectedPublishDatePayload} : '' :
                      getters.getSelectedBookAgePayload ? {conditions_age: getters.getSelectedBookAgePayload} : ''
                  ),
                  ...(state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE ? {conditions_popularGoods: state.selectedTopPopularity} : ''),
                  ...(state.tags ? {tags: state.tags} : ''),
                  ...(payload && payload.a ? {a:payload.a} : ''),
                  ...(payload && payload.b ? {b:payload.b} : ''),
                  ...(payload && payload.img_weight ? {img_weight:payload.img_weight} : ''),
              },
          })
          .then(({data}) => {
              if (!data) {
                  return;
              }

              if (state.vectorSearch.page <= 2) {
                  commit('applyVectorSearchResults', [data.items]);
              } else {
                  commit('applySearchResults', data.items);
                  commit('applyVectorSearchResults', [data.items]); // 検証用に配列で持たせている
              }

              if (Number.isFinite(data.count)) {
                  commit('mutateVectorSearchCount', data.count);
              }

              //追加の検索結果がない場合
              if (data.items.length === 0 || data.items.length < maxItemNumber) {
                  commit('mutateIsVectorSearchComplete', true);
              }

              //検索結果が全くない
              if (getters.getVectorSearchResultsCount === 0) {
                  commit('mutateIsVectorDataNotFound', true);
              }
              commit('mutateIsVectorSearchLoading', false);
          })
          .catch((error) => {
              commit('mutateIsVectorSearchError', true);
              commit('mutateIsVectorSearchLoading', false);
          });
    },
    changeStockCondition({dispatch, commit}, payload) {
        commit('mutateIsStock', payload);
        dispatch('research');
    },
    setAllGenre({commit, getters}) {
        getters.getSearchCategory[0] && commit('mutateSelectedCategories', Object.keys(getters.getSearchCategory[0]));
    },
    updateSelectedItemsIds(store, item) {
        store.commit('setSelectedItemsIds', item);
    },
    updateApiEndPoint(store, item) {
        store.commit('setApiEndPoint', item);
    },
    updateGenreApiEndPoint(store, item) {
        store.commit('setGenreApiEndPoint', item);
    },
    updateIsAllSelected(store, item) {
        store.commit('setIsAllSelected', item);
    }
}

function pushToStoreIfFavorite(items, dispatch) {
    const vsCatalogIDs = items.map((item) => item.vs_catalog_id);
    if (0 < vsCatalogIDs.length) {
        axios.post('/api/library/folder/favorite/items/intersection', {
            vs_catalog_id: vsCatalogIDs,

        }).then(({data: favoriteVsCatalogIDs}) => {
            favoriteVsCatalogIDs.forEach(vsCatalogID => {
                dispatch('favorite/pushToFavoriteStore', vsCatalogID, {root: true});
            })

        }).catch(e => {
            if (e.response.status !== 403) {
                console.error(e);
            }
        });
    }
}

function updateFavoriteFlag(items) {
    const vsCatalogIDs = items.filter(v => v).map((item) => item.vs_catalog_id);
    if(!vsCatalogIDs){
        return;
    }
    if (0 < vsCatalogIDs.length) {
        return axios.post('/api/library/folder/favorite/items/intersection', {
            vs_catalog_id: vsCatalogIDs,
        }).then(({data: favoriteVsCatalogIDs}) => {
            //favoriteVsCatalogIDsと一致するitemsのisFavoriteをtrueにする
            const favoriteSet = new Set(favoriteVsCatalogIDs);
            items.forEach(item => {
                Vue.set(item, 'isFavorite', favoriteSet.has(item.vs_catalog_id));
            });
        }).catch(e => {
            if (e.response.status !== 403) {
                console.error(e);
            }
        });
    }
}

const searchGetters = {
    getPageNumber(state) {
        return state.page;
    },
    getIsStock(state) {
        return state.isStock;
    },
    getIsAllGenreChecked(state) {
        return state.isAllGenreChecked;
    },
    getSearchResults(state) {
        // 重複する書誌を除いて返却
        const resultOfRemoved = state.search_results.filter(function (x, i, items) {
            // vs_catalog_id がnullの場合、またはコミックセットの場合はチェックしない（子コミックセットを考慮）
            if (!x.vs_catalog_id || x.vs_catalog_id.startsWith("VC")) {
                return x
            }
            return items.findIndex(function (y) {
                return y.vs_catalog_id === x.vs_catalog_id;
            }) === i
        });

        // 重複した書誌の数をセット
        state.numberOfDuplicates = state.search_results.length - resultOfRemoved.length;

        return resultOfRemoved;
    },
    getSearchResultsCount(state) {
        return state.search_results.length;
    },
    getSearchResultsTitle(state) {
        return state.search_results_title;
    },
    getVectorSearchResults(state) {
        return state.vectorSearch.results;
    },
    getVectorSearchResultsCount(state) {
        return state.vectorSearch.results[0] ? state.vectorSearch.results[0].length : 0;
    },
    getSearchCategory(state) {
        return state.search_results_genre;
    },
    getSelectedCategories(state) {
        return state.selectedCategories;
    },
    getSelectedSortCode(state) {
        return state.selectedSortCode;
    },
    getIsLoading(state) {
        return state.isLoading;
    },
    getVectorSearchIsLoading(state) {
        return state.vectorSearch.isLoading;
    },
    getSelectedSellPrices(state) {
        return state.selectedSellPrices;
    },
    getSelectedDiscountRate(state) {
        return state.selectedDiscountRate;
    },
    getSelectedPublishDate(state) {
        return state.selectedPublishDate;
    },
    getSelectedBookAge(state) {
        return state.selectedBookAge;
    },
    getSelectedTopPopularity(state) {
        return state.selectedTopPopularity;
    },
    getIsPriceDisplay(state) {
        return state.isPriceDisplay;
    },
    getIsPublishDateDisplay(state) {
        return state.isPublishDateDisplay;
    },
    getSelectedSellPricePayload(state) {
        let selectedSellPrices = [];
        state.selectedSellPrices.forEach((item, key) => {
            selectedSellPrices[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
        });
        return (!selectedSellPrices[0] && !selectedSellPrices[1]) ? null : selectedSellPrices;
    },
    getSelectedDiscountRatePayload(state) {
        let selectedDiscountRate = [];
        state.selectedDiscountRate.forEach((item, key) => {
            selectedDiscountRate[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
        });
        return (!selectedDiscountRate[0] && !selectedDiscountRate[1]) ? null : selectedDiscountRate;
    },
    getSelectedPublishDatePayload(state) {
        let selectedPublishDate = [];
        state.selectedPublishDate.forEach((item, key) => {
            selectedPublishDate[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
        });
        return (!selectedPublishDate[0] && !selectedPublishDate[1]) ? null : selectedPublishDate.reverse();
    },
    getSelectedBookAgePayload(state) {
        let selectedBookAge = [];
        state.selectedBookAge.forEach((item, key) => {
            selectedBookAge[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
        });
        return (!selectedBookAge[0] && !selectedBookAge[1]) ? null : selectedBookAge.reverse();
    },
    getIsDisplayOverlayLoading(state) {
        return state.isDisplayOverlayLoading;
    },
    getCount(state) {
        let resultCount = 0;
        // ベクトル検索結果がある場合は、通常検索結果とベクトル検索結果の件数を返却
        if(state.vectorSearch.count) {
            resultCount = state.count + state.vectorSearch.count - state.numberOfDuplicates;
        } else {
            resultCount = state.count - state.numberOfDuplicates;
        }

        return resultCount;
    },
    getVectorSearchCount(state) {
        return state.vectorSearch.count;
    },
    getIsReSearch(state) {
        return state.isReSearch;
    },
    getIsItemSelectable(state) {
        return state.isItemSelectable;
    },
    getSelectedItemsIds(state) {
        return state.selectedItemsIds
    },
    getApiEndPoint(state) {
        return state.apiEndPoint
    },
    getSelectedItemsCount(state) {
        return state.selectedItemsIds.length
    },
    getFilterLibraryIdsBySelectedItems(state) {
        // 古いライブラリコピー用 asinをフォルダ内のアイテム重複で見ているため
        let selectedLibraryIds = [];
        if (!state.search_results) {
            return selectedLibraryIds;
        }
        state.selectedItemsIds.forEach((id) => {
            const item = state.search_results.find(element => element.library_item_id === id)
            if (item && "library_item_id" in item) {
                selectedLibraryIds.push({
                    seq: item.library_item_id,
                    asin: item.asinCode,
                    vsCatalogId: item.vs_catalog_id
                })
            }
        });
        return selectedLibraryIds;
    },
    getTitleSearchCount(state) {
        return state.titleSearchCount;
    },
    getIsComplete(state) {
        return state.isComplete;
    },
    getIsVectorSearchComplete(state) {
        return state.vectorSearch.isComplete;
    },
    getFavoriteItemsIds(state) {
        return state.favoriteItemsIds;
    },
    getIsDataNotFound(state){
        return state.isDateNotFound;
    },
    getVectorSearchIsDataNotFound(state) {
        return state.vectorSearch.isDataNotFound;
    },
    getVectorSearchIsSearchError(state) {
        return state.vectorSearch.isSearchError;
    },
    getIsConditionSelected(state, getters) {
        return getters.getSelectedSellPricePayload !== null
          || getters.getSelectedDiscountRatePayload !== null
          || getters.getSelectedPublishDatePayload !== null
          || getters.getSelectedBookAgePayload !== null
          || getters.getSelectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE
          || getters.getSelectedCategories.length !== 0
          || getters.getIsStock;
    },
    getShowSortingDialog(state) {
        return state.showSortingDialog;
    },
    getIsAllSelected(state) {
        return state.isAllSelected;
    },
    getAlternativeSearchKeywords(state){
        return state.alterNativeSearchWords;
    },
    getPageIsOneAndIsLoading(state){
        return state.isLoading && state.page===1
    },
    getHasValidSearchWordAndPageIsOneAndIsNotLoading(state){
        return state.search_word && state.search_word.length<=20 && !state.isLoading && state.page===1
    }
}

const defaultSearchState = () => {
    return {
        page: 0,
        isDisplayNavbar: false,
        isDisplayOverlayLoading: false,
        isReSearch: false,
        isGetGenre: false,
        isAllGenreChecked: true,
        isStock: false,
        search_word: '',
        kana_readings: [],
        other_readings: '',
        search_results: [],
        search_results_genre: [],
        search_results_title: [],
        selectedCategories: [],
        selectedSortCode: 1,
        selectedPublishDate: [0, -1],
        selectedTopPopularity: -1,
        selectedSellPrices: [0, -1],
        selectedDiscountRate: [0, -1],
        selectedBookAge: [0, -1],
        isDateNotFound: false,
        isSearchError: false,
        isComplete: false,
        isLoading: false,
        isPriceDisplay: true,
        isPublishDateDisplay: true,
        count: null,
        isItemSelectable: false,
        selectedItemsIds: [],
        apiEndPoint: "/api/search",
        genreApiEndPoint: "/api/searchGenre",
        tags: null,
        tagSearchDisplaySize: null,
        titleSearchDisplaySize: null,
        titleSearchCount: 0,
        vectorSearch: {
            results: [],
            count: 0,
            page: 0,
            isComplete: false,
            isLoading: false,
            isDataNotFound: false,
            isSearchError: false,
        },
        numberOfDuplicates: 0,
        showSortingDialog: false,
        isAllSelected: false,
        alterNativeSearchWords:[]
    }
}

const moduleSearch = {
    namespaced: true,
    state: defaultSearchState(),
    mutations: searchMutations,
    actions: searchActions,
    getters: searchGetters
}

export {moduleSearch};